@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  color: #2d3236;
  height: 100%;
}
html,
button,
input,
select,
textarea {
  font-family: 'PTSans', sans-serif;
}
body {
  font-size: 16px;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  *zoom: 1;
}
body:before,
body:after {
  content: "";
  display: table;
}
body:after {
  clear: both;
}
.draggable {
  cursor: url("../images/openhand.cur"), auto;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.dragging {
  cursor: url("../images/closedhand.cur"), auto;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
.salmon-bg {
  background-color: #f7bf9c;
}
.capitalize {
  text-transform: capitalize;
}
.relative {
  position: relative;
}
ol.example li.placeholder {
  position: relative;
}
ol.example li.placeholder:before {
  position: absolute;
}
h2 {
  font-size: 18px;
  font-size: 1.8rem;
}
h3 {
  font-size: 16px;
  font-size: 1.6rem;
}
input,
textarea,
div.content,
.password-input {
  border: 1px solid #bfbfbf;
  resize: none;
  outline: none;
}
input:focus,
textarea:focus,
div.content:focus,
.password-input:focus {
  outline: #9bbde0 auto 5px;
}
input.number.number--with-arrows {
  padding-right: 0px;
}
input[type=number]:not(.number.number--with-arrows) {
  -moz-appearance: textfield;
}
input[type=number]:not(.number.number--with-arrows)::-webkit-outer-spin-button,
input[type=number]:not(.number.number--with-arrows)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.password-input {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-right: 5px;
}
.password-input input {
  border: none;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex: 2;
  flex-grow: 2;
}
.password-input .blind-eye:before {
  font-size: 1.5em;
}
*:hover {
  text-decoration: none !important;
}
select:focus,
textarea:focus,
input:focus,
button:focus {
  outline: none !important;
}
input:not([type]),
input[type=text],
input[type=number],
input[type=email],
select {
  display: inline-block;
  padding: 0 8px;
  font-size: 14px;
  line-height: 30px;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.expanded select,
.separated select,
.question-edit select {
  border: 1px solid #bfbfbf;
  width: 57px;
  padding: 0 0 0 8px;
  height: 32px;
  -webkit-appearance: menulist;
  -webkit-rtl-ordering: logical;
}
.question-edit input,
.separated input {
  height: 32px;
}
textarea,
div.content {
  padding: 5px;
}
input[disabled] {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
input[type=radio] {
  -webkit-appearance: none;
  border: 1px solid #bfbfbf;
  display: inline-block;
  margin: 0 0.25em 0 0;
  padding: 0;
  width: 13px;
  height: 13px;
  -webkit-border-radius: 0.25em;
  border-radius: 0.25em;
  vertical-align: text-top;
  background: #fff;
}
input[type=radio] {
  -webkit-border-radius: 2em;
  border-radius: 2em;
}
input[type=radio]:checked {
  background: url("../images/radiobutton.png") center center no-repeat;
}
input[type=checkbox] {
  -webkit-appearance: none;
  background: #fff;
  display: inline-block;
  width: 12px;
  min-width: 12px;
  height: 12px;
}
input[type=checkbox]:checked {
  background: url("../images/check.png") center center no-repeat;
}
.question-mark-sign {
  display: inline-block;
  cursor: pointer;
  height: 15px;
  width: 15px;
  line-height: 15px;
  border: 1px solid #000;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
}
.question-mark-sign:before {
  content: '?';
}
span.info-sign {
  display: block;
  width: 11px;
  height: 11px;
  content: url("../images/info.png");
  position: absolute;
  top: 2px;
  right: 2px;
}
.standard-table {
  width: 100%;
}
.standard-table thead {
  background: #dedede;
}
.standard-table th {
  border: 2px solid #fff;
  padding: 0;
  font-weight: normal;
}
.standard-table th.with-info,
.standard-table td.with-info {
  position: relative;
  padding-right: 14px;
}
.standard-table td {
  border: 2px solid #fff;
  padding: 0;
}
sup[data-footnote-id] span.underline {
  text-decoration: underline;
}
.error-message {
  margin: 10px 0;
  color: #9e0000;
}
.message-box {
  background-color: #d2dfed;
  color: #1a008d;
  border: 1px solid #bfbfbf;
  display: block;
  padding: 1rem;
  margin-bottom: 2rem;
}
::selection,
::-moz-selection {
  background: #9bbde0;
  color: #fff;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
  background-color: #cbcbcb;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.ep-modal.full-size.references-insert-modal {
  top: 30vh;
  width: 600px;
}
.ep-modal.full-size.references-insert-modal h2 {
  text-align: center;
}
.ep-modal.full-size.references-insert-modal .references-filter input {
  width: 100%;
}
.ep-modal.full-size.references-insert-modal .references-list {
  position: relative;
  margin-top: 20px;
}
.ep-modal.full-size.references-insert-modal .references-list .initial-references-list {
  max-height: 150px;
  overflow-y: auto;
}
.ep-modal.full-size.references-insert-modal .references-list .references-list-virtualized {
  height: 250px;
}
.ep-modal.full-size.references-insert-modal .references-list label {
  margin-top: 5px;
}
.ep-modal.full-size.references-insert-modal .references-list label div:not(:first-child) {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.ep-modal.full-size.references-insert-modal .references-list label span {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference {
  position: relative;
  height: 17px;
  top: -5px;
  left: -2px;
  width: 16px;
  font-size: 12px;
  bottom: 0;
  right: 0;
}
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference,
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference:link,
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference:visited,
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference:hover,
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference:active {
  color: inherit;
  text-decoration: none;
}
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference:before {
  font-family: 'gdt';
  content: '\e074';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference * {
  margin-left: 0.5rem;
}
.ep-modal.full-size.references-insert-modal .references-list label span.missing-reference:before {
  color: #f67c61;
}
.ep-modal.full-size.references-insert-modal .references-list label span i {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.ep-modal.full-size.references-insert-modal .references-list label .attachment-link {
  margin-left: 10px;
  display: inline-block;
  float: right;
}
.ep-modal.full-size.references-insert-modal .references-list label input {
  margin-right: 10px;
}
.info-message-modal {
  width: 370px;
  min-height: 200px;
  text-align: center;
}
.info-message-modal .modal-close {
  z-index: 999;
}
.info-message-modal .message {
  position: relative;
  height: 100%;
  padding-top: 25px;
}
.info-message-modal .message div {
  margin-bottom: 72px;
}
.info-message-modal button {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
}
.confirmation-modal-react {
  margin-top: 15% !important;
  width: 532px;
  height: 190px;
}
.outer-container {
  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
}
.outer-container.organizations-screen-container,
.outer-container.welcome-screen-container {
  top: 40px;
  padding-top: 10px;
  background: -webkit-linear-gradient(#000f2e, #d8dadf);
  background: -moz-linear-gradient(#000f2e, #d8dadf);
  background: -o-linear-gradient(#000f2e, #d8dadf);
  background: -ms-linear-gradient(#000f2e, #d8dadf);
  background: linear-gradient(#000f2e, #d8dadf);
}
.outer-container.organizations-screen-container .organizations-container {
  padding: 0 90px;
}
.outer-container.organizations-screen-container .organizations-container .workspace-list {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}
.outer-container.organizations-screen-container .text {
  font-size: 14px;
  line-height: 20px;
}
.outer-container.organizations-screen-container h1 {
  margin: 43px 0;
  font-size: 21px;
  color: #fff;
}
.outer-container.organizations-screen-container .accounts-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
.outer-container.organizations-screen-container .accounts-list .accounts-list-item {
  margin-right: 20px;
}
.outer-container.organizations-screen-container .start-personal-account-option,
.outer-container.organizations-screen-container .accounts-list-item {
  width: 290px;
  height: 187px;
  padding: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 7px 5px rgba(0,0,0,0.5);
  box-shadow: 0px 7px 5px rgba(0,0,0,0.5);
}
.outer-container.organizations-screen-container .accounts-list-item {
  background-color: #fff;
}
.outer-container.organizations-screen-container .accounts-list-item .option-content {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}
.outer-container.organizations-screen-container .accounts-list-item .option-content .logo-wrapper {
  width: 218px;
  height: 80px;
}
.outer-container.organizations-screen-container .accounts-list-item .option-content .logo-wrapper .organization-logo {
  max-width: 100%;
  max-height: 100%;
}
.outer-container.organizations-screen-container .accounts-list-item button.open-account {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.outer-container.organizations-screen-container .start-personal-account-option {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.outer-container.organizations-screen-container .start-personal-account-option:hover {
  font-weight: bold;
}
.outer-container.organizations-screen-container .organization-name {
  margin-bottom: 8px;
  color: #fff;
  font-weight: bold;
}
.outer-container.organizations-screen-container .avatar {
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
}
.information-tooltip {
  padding: 15px;
  color: #fff;
  background-color: #000048;
  font-size: 14px;
  font-size: 1.4rem;
}
.licence-tooltip-text {
  text-align: center;
}
.licence-tooltip-text a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}
.licence-tooltip-text a:hover {
  color: #ccc;
}
.licence-tooltip-text a.licence-tooltip-text__link {
  margin-top: 20px;
}
#right-container {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  position: relative;
  top: 8px;
  height: calc(100vh - 40px);
  overflow: hidden;
}
#right-container.no-topbar #topbar {
  display: none !important;
}
#right-container.no-topbar #page-container {
  height: calc(100vh - 90px + 36px);
}
#page-container {
  position: relative;
  margin: 0;
  padding: 10px 10px 0px;
  height: calc(100vh - 90px);
  overflow-y: hidden;
  border-top-width: 0;
}
#page-container.organizations-screen,
#page-container.welcome-screen {
  background: url("../images/svg/grade-pro-bg.svg") bottom repeat-x;
  -webkit-background-size: 396px 148px;
  -moz-background-size: 396px 148px;
  background-size: 396px 148px;
}
#page-container > div:first-child {
  height: 100%;
}
#presentations-module {
  overflow-y: auto;
}
#presentations-module .text-area-container,
#presentations-module textarea {
  min-height: 150px !important;
  font: normal 10pt 'Arial Narrow', Arial, sans-serif !important;
}
#topbar {
  height: 36px;
  background: #fff;
}
#topbar h2 {
  margin: 0;
  line-height: 36px;
}
#searchbox-container {
  width: 190px;
  float: left;
}
#toolbar-container .toolbar a {
  display: block;
  float: left;
}
#toolbar-container .toolbar a,
#toolbar-container .toolbar a:link,
#toolbar-container .toolbar a:visited,
#toolbar-container .toolbar a:hover,
#toolbar-container .toolbar a:active {
  color: inherit;
  text-decoration: none;
}
#toolbar-container .questions {
  line-height: 36px;
  position: absolute;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#toolbar-container .questions .questions-list-container {
  padding: 0 5px;
  margin-right: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
  box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
  max-height: 85vh;
  overflow-y: auto;
}
#toolbar-container .questions .questions-list-container .questions-list-container__question {
  cursor: pointer;
}
#toolbar-container .questions .questions-list-container .questions-list-container__question a,
#toolbar-container .questions .questions-list-container .questions-list-container__question a:hover,
#toolbar-container .questions .questions-list-container .questions-list-container__question a:visited,
#toolbar-container .questions .questions-list-container .questions-list-container__question a:active {
  text-decoration: none;
  color: currentColor;
}
#toolbar-container .questions-menu {
  display: none;
  background-color: #eaeaea;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  background-color: #fff;
  padding: 5px;
  position: absolute;
  top: 41px;
  margin-left: -10px;
  z-index: 100;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
#toolbar-container .questions-menu button.danger,
#toolbar-container .questions-menu input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
#toolbar-container .questions-menu button.danger:hover:not(:disabled),
#toolbar-container .questions-menu input.danger:hover:not(:disabled) {
  background-color: rgba(242,222,222,0.5);
}
#toolbar-container .questions-menu button.danger[disabled],
#toolbar-container .questions-menu input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
#toolbar-container .questions-menu button.finish,
#toolbar-container .questions-menu input.finish {
  background-color: #c9dac2;
  color: #036707;
}
#toolbar-container .questions-menu button.finish:hover:not(:disabled),
#toolbar-container .questions-menu input.finish:hover:not(:disabled) {
  background-color: rgba(201,218,194,0.5);
}
#toolbar-container .questions-menu button.finish[disabled],
#toolbar-container .questions-menu input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
#toolbar-container .questions-menu button.loading,
#toolbar-container .questions-menu input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
#toolbar-container .questions-menu ol {
  display: block;
  border-style: none;
  overflow-y: auto;
  width: 100%;
  position: relative;
}
#toolbar-container .questions-menu ol li.question-group {
  height: auto;
  min-height: 50px;
  margin-bottom: 5px;
  background: inherit;
  border: 1px solid #009cca;
  padding: 5px;
}
#toolbar-container .questions-menu ol li.question-group:before {
  content: initial;
}
#toolbar-container .questions-menu ol li.question-group > .caption {
  font-weight: bold;
}
#toolbar-container .questions-menu ol .caption {
  height: 40px;
  white-space: normal;
  padding-right: 5px;
  font-size: 16px;
}
#toolbar-container .questions-menu ol .caption .ellipsis-wrapper {
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 40px;
}
#toolbar-container .questions-menu ol .caption a {
  height: auto;
}
#toolbar-container .questions-menu .mCSB_container {
  margin-right: inherit;
}
#toolbar-container menu[type=toolbar] {
  padding: 0;
  margin: 0;
  list-style: none;
  float: right;
}
#toolbar-container menu[type=toolbar] a,
#toolbar-container menu[type=toolbar] button {
  width: 20px;
  height: 20px;
  margin: 7px 10px;
  float: left;
}
#toolbar-container menu[type=toolbar] span.toolbar-separator {
  display: inline-block;
  height: 36px;
  border-left: 1px solid #bfbfbf;
  float: left;
  margin: 0 10px;
}
#toolbar-container menu[type=toolbar].project {
  width: 30px;
}
#toolbar-container span.caption {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 36px;
  padding-left: 15px;
  font-weight: bold;
}
#toolbar-container span.caption {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 36px;
  padding-left: 15px;
  font-weight: bold;
}
#evidence-table-toolbar-container .breadcrumbs,
#toolbar-container .breadcrumbs,
.etd-section-title .breadcrumbs,
.breadcrumbs-container .breadcrumbs {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #f2f2f2;
  min-width: 920px;
  font-size: 0;
}
#evidence-table-toolbar-container .breadcrumbs li,
#toolbar-container .breadcrumbs li,
.etd-section-title .breadcrumbs li,
.breadcrumbs-container .breadcrumbs li {
  display: inline-block;
  position: relative;
}
#evidence-table-toolbar-container .breadcrumbs li.breadcrumbs-tab,
#toolbar-container .breadcrumbs li.breadcrumbs-tab,
.etd-section-title .breadcrumbs li.breadcrumbs-tab,
.breadcrumbs-container .breadcrumbs li.breadcrumbs-tab {
  max-height: 35px;
  padding: 0;
  margin: 0;
  overflow: visible;
  vertical-align: middle;
  text-align: center;
  background-color: #f2f2f2;
  cursor: pointer;
}
#evidence-table-toolbar-container .breadcrumbs li.disabled,
#toolbar-container .breadcrumbs li.disabled,
.etd-section-title .breadcrumbs li.disabled,
.breadcrumbs-container .breadcrumbs li.disabled {
  cursor: default;
}
#evidence-table-toolbar-container .breadcrumbs li span,
#toolbar-container .breadcrumbs li span,
.etd-section-title .breadcrumbs li span,
.breadcrumbs-container .breadcrumbs li span {
  display: inline-block;
}
#evidence-table-toolbar-container .breadcrumbs li.selected.selected,
#toolbar-container .breadcrumbs li.selected.selected,
.etd-section-title .breadcrumbs li.selected.selected,
.breadcrumbs-container .breadcrumbs li.selected.selected {
  font-weight: bold;
  background-color: #fff;
}
#evidence-table-toolbar-container .breadcrumbs li.selected.selected span,
#toolbar-container .breadcrumbs li.selected.selected span,
.etd-section-title .breadcrumbs li.selected.selected span,
.breadcrumbs-container .breadcrumbs li.selected.selected span {
  background-color: #fff;
}
#evidence-table-toolbar-container .breadcrumbs li.selected.selected span:before,
#toolbar-container .breadcrumbs li.selected.selected span:before,
.etd-section-title .breadcrumbs li.selected.selected span:before,
.breadcrumbs-container .breadcrumbs li.selected.selected span:before {
  border-left-color: #fff;
}
#evidence-table-toolbar-container .breadcrumbs li.with-additional-label .text-label,
#toolbar-container .breadcrumbs li.with-additional-label .text-label,
.etd-section-title .breadcrumbs li.with-additional-label .text-label,
.breadcrumbs-container .breadcrumbs li.with-additional-label .text-label,
#evidence-table-toolbar-container .breadcrumbs li.with-additional-label .additional-text-label,
#toolbar-container .breadcrumbs li.with-additional-label .additional-text-label,
.etd-section-title .breadcrumbs li.with-additional-label .additional-text-label,
.breadcrumbs-container .breadcrumbs li.with-additional-label .additional-text-label {
  display: block;
  overflow: visible;
  line-height: 16px;
  height: 17px;
}
#evidence-table-toolbar-container .breadcrumbs li.with-additional-label .text-label,
#toolbar-container .breadcrumbs li.with-additional-label .text-label,
.etd-section-title .breadcrumbs li.with-additional-label .text-label,
.breadcrumbs-container .breadcrumbs li.with-additional-label .text-label {
  font-size: 16px;
}
#evidence-table-toolbar-container .breadcrumbs li.with-additional-label .additional-text-label,
#toolbar-container .breadcrumbs li.with-additional-label .additional-text-label,
.etd-section-title .breadcrumbs li.with-additional-label .additional-text-label,
.breadcrumbs-container .breadcrumbs li.with-additional-label .additional-text-label {
  font-size: 14px;
}
#evidence-table-toolbar-container .breadcrumbs li a,
#toolbar-container .breadcrumbs li a,
.etd-section-title .breadcrumbs li a,
.breadcrumbs-container .breadcrumbs li a,
#evidence-table-toolbar-container .breadcrumbs li span,
#toolbar-container .breadcrumbs li span,
.etd-section-title .breadcrumbs li span,
.breadcrumbs-container .breadcrumbs li span {
  padding: 0 35px 0 50px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  overflow: hidden;
}
#evidence-table-toolbar-container .breadcrumbs li a:after,
#toolbar-container .breadcrumbs li a:after,
.etd-section-title .breadcrumbs li a:after,
.breadcrumbs-container .breadcrumbs li a:after,
#evidence-table-toolbar-container .breadcrumbs li span:after,
#toolbar-container .breadcrumbs li span:after,
.etd-section-title .breadcrumbs li span:after,
.breadcrumbs-container .breadcrumbs li span:after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  z-index: 2;
  left: 100%;
  border: 18px solid transparent;
  border-left: 18px solid #b3b3b3;
}
#evidence-table-toolbar-container .breadcrumbs li a:before,
#toolbar-container .breadcrumbs li a:before,
.etd-section-title .breadcrumbs li a:before,
.breadcrumbs-container .breadcrumbs li a:before,
#evidence-table-toolbar-container .breadcrumbs li span:before,
#toolbar-container .breadcrumbs li span:before,
.etd-section-title .breadcrumbs li span:before,
.breadcrumbs-container .breadcrumbs li span:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 100%;
  z-index: 3;
  width: 0;
  height: 0;
  border: 18px solid transparent;
  border-left: 12px solid #f2f2f2;
}
#evidence-table-toolbar-container .breadcrumbs li a.disabled,
#toolbar-container .breadcrumbs li a.disabled,
.etd-section-title .breadcrumbs li a.disabled,
.breadcrumbs-container .breadcrumbs li a.disabled,
#evidence-table-toolbar-container .breadcrumbs li span.disabled,
#toolbar-container .breadcrumbs li span.disabled,
.etd-section-title .breadcrumbs li span.disabled,
.breadcrumbs-container .breadcrumbs li span.disabled {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  color: rgba(45,50,54,0.3);
}
#evidence-table-toolbar-container .breadcrumbs li:first-child span,
#toolbar-container .breadcrumbs li:first-child span,
.etd-section-title .breadcrumbs li:first-child span,
.breadcrumbs-container .breadcrumbs li:first-child span {
  padding-left: 35px;
}
#evidence-table-toolbar-container .breadcrumbs li:last-child a:before,
#toolbar-container .breadcrumbs li:last-child a:before,
.etd-section-title .breadcrumbs li:last-child a:before,
.breadcrumbs-container .breadcrumbs li:last-child a:before,
#evidence-table-toolbar-container .breadcrumbs li:last-child span:before,
#toolbar-container .breadcrumbs li:last-child span:before,
.etd-section-title .breadcrumbs li:last-child span:before,
.breadcrumbs-container .breadcrumbs li:last-child span:before,
#evidence-table-toolbar-container .breadcrumbs li:last-child a:after,
#toolbar-container .breadcrumbs li:last-child a:after,
.etd-section-title .breadcrumbs li:last-child a:after,
.breadcrumbs-container .breadcrumbs li:last-child a:after,
#evidence-table-toolbar-container .breadcrumbs li:last-child span:after,
#toolbar-container .breadcrumbs li:last-child span:after,
.etd-section-title .breadcrumbs li:last-child span:after,
.breadcrumbs-container .breadcrumbs li:last-child span:after {
  border: 0;
}
#evidence-table-toolbar-container menu[type=toolbar],
#toolbar-container menu[type=toolbar],
.etd-section-title menu[type=toolbar],
.breadcrumbs-container menu[type=toolbar] {
  padding: 0;
  margin: 0;
  list-style: none;
  float: right;
}
#evidence-table-toolbar-container menu[type=toolbar] a,
#toolbar-container menu[type=toolbar] a,
.etd-section-title menu[type=toolbar] a,
.breadcrumbs-container menu[type=toolbar] a,
#evidence-table-toolbar-container menu[type=toolbar] button,
#toolbar-container menu[type=toolbar] button,
.etd-section-title menu[type=toolbar] button,
.breadcrumbs-container menu[type=toolbar] button {
  width: 20px;
  height: 20px;
  margin: 7px 10px;
  float: left;
}
#evidence-table-toolbar-container menu[type=toolbar] span.toolbar-separator,
#toolbar-container menu[type=toolbar] span.toolbar-separator,
.etd-section-title menu[type=toolbar] span.toolbar-separator,
.breadcrumbs-container menu[type=toolbar] span.toolbar-separator {
  display: inline-block;
  height: 36px;
  border-left: 1px solid #bfbfbf;
  float: left;
  margin: 0 10px;
}
#evidence-table-toolbar-container menu[type=toolbar].project,
#toolbar-container menu[type=toolbar].project,
.etd-section-title menu[type=toolbar].project,
.breadcrumbs-container menu[type=toolbar].project {
  width: 30px;
}
#evidence-table-toolbar-container {
  height: 49px;
}
#evidence-table-toolbar-container menu[type=toolbar] button {
  margin: 17px 10px 12px;
}
span.icon,
span.button,
button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}
span.icon,
span.button,
button,
span.icon:link,
span.button:link,
button:link,
span.icon:visited,
span.button:visited,
button:visited,
span.icon:hover,
span.button:hover,
button:hover,
span.icon:active,
span.button:active,
button:active {
  color: inherit;
  text-decoration: none;
}
span.icon.settings:before,
span.button.settings:before,
button.settings:before {
  font-family: 'gdt';
  content: '\e001';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.settings *,
span.button.settings *,
button.settings * {
  margin-left: 0.5rem;
}
span.icon.suggestion:before,
span.button.suggestion:before,
button.suggestion:before {
  font-family: 'gdt';
  content: '\e002';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.suggestion *,
span.button.suggestion *,
button.suggestion * {
  margin-left: 0.5rem;
}
span.icon.table-mode:before,
span.button.table-mode:before,
button.table-mode:before {
  font-family: 'gdt';
  content: '\e004';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.table-mode *,
span.button.table-mode *,
button.table-mode * {
  margin-left: 0.5rem;
}
span.icon.activate:before,
span.button.activate:before,
button.activate:before {
  font-family: 'gdt';
  content: '\e005';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.activate *,
span.button.activate *,
button.activate * {
  margin-left: 0.5rem;
}
span.icon.feedback-icon:before,
span.button.feedback-icon:before,
button.feedback-icon:before {
  font-family: 'gdt';
  content: '\e006';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.feedback-icon *,
span.button.feedback-icon *,
button.feedback-icon * {
  margin-left: 0.5rem;
}
span.icon.context-help:before,
span.button.context-help:before,
button.context-help:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.context-help *,
span.button.context-help *,
button.context-help * {
  margin-left: 0.5rem;
}
span.icon.export:before,
span.button.export:before,
button.export:before {
  font-family: 'gdt';
  content: '\e009';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.export *,
span.button.export *,
button.export * {
  margin-left: 0.5rem;
}
span.icon.archive:before,
span.button.archive:before,
button.archive:before {
  font-family: 'gdt';
  content: '\e010';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.archive *,
span.button.archive *,
button.archive * {
  margin-left: 0.5rem;
}
span.icon.edit:before,
span.button.edit:before,
button.edit:before {
  font-family: 'gdt';
  content: '\e012';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.edit *,
span.button.edit *,
button.edit * {
  margin-left: 0.5rem;
}
span.icon.reset:before,
span.button.reset:before,
button.reset:before {
  font-family: 'gdt';
  content: '\e014';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.reset *,
span.button.reset *,
button.reset * {
  margin-left: 0.5rem;
}
span.icon.reset.icon-right:before,
span.button.reset.icon-right:before,
button.reset.icon-right:before {
  content: "";
}
span.icon.reset.icon-right:after,
span.button.reset.icon-right:after,
button.reset.icon-right:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e014';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.footnotes:before,
span.button.footnotes:before,
button.footnotes:before {
  font-family: 'gdt';
  content: '\e015';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.footnotes *,
span.button.footnotes *,
button.footnotes * {
  margin-left: 0.5rem;
}
span.icon.search:before,
span.button.search:before,
button.search:before {
  font-family: 'gdt';
  content: '\e016';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.search *,
span.button.search *,
button.search * {
  margin-left: 0.5rem;
}
span.icon.comment-blank-hide,
span.button.comment-blank-hide,
button.comment-blank-hide {
  font-size: 25px;
  line-height: 15px;
}
span.icon.comment-blank-hide:before,
span.button.comment-blank-hide:before,
button.comment-blank-hide:before {
  font-family: 'gdt';
  content: '\e017';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.comment-blank-hide *,
span.button.comment-blank-hide *,
button.comment-blank-hide * {
  margin-left: 0.5rem;
}
span.icon.arrow-down:before,
span.button.arrow-down:before,
button.arrow-down:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.arrow-down *,
span.button.arrow-down *,
button.arrow-down * {
  margin-left: 0.5rem;
}
span.icon.arrow-right:before,
span.button.arrow-right:before,
button.arrow-right:before {
  font-family: 'gdt';
  content: '\e019';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.arrow-right *,
span.button.arrow-right *,
button.arrow-right * {
  margin-left: 0.5rem;
}
span.icon.arrow-left,
span.button.arrow-left,
button.arrow-left {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
span.icon.arrow-left:before,
span.button.arrow-left:before,
button.arrow-left:before {
  font-family: 'gdt';
  content: '\e019';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.arrow-left *,
span.button.arrow-left *,
button.arrow-left * {
  margin-left: 0.5rem;
}
span.icon.share:before,
span.button.share:before,
button.share:before {
  font-family: 'gdt';
  content: '\e020';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.share *,
span.button.share *,
button.share * {
  margin-left: 0.5rem;
}
span.icon.save:before,
span.button.save:before,
button.save:before {
  font-family: 'gdt';
  content: '\e021';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.save *,
span.button.save *,
button.save * {
  margin-left: 0.5rem;
}
span.icon.save-proceed:before,
span.button.save-proceed:before,
button.save-proceed:before {
  font-family: 'gdt';
  content: '\e021';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.save-proceed *,
span.button.save-proceed *,
button.save-proceed * {
  margin-left: 0.5rem;
}
span.icon.delete:before,
span.button.delete:before,
button.delete:before,
span.icon.delete-question:before,
span.button.delete-question:before,
button.delete-question:before {
  font-family: 'gdt';
  content: '\e022';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.delete *,
span.button.delete *,
button.delete *,
span.icon.delete-question *,
span.button.delete-question *,
button.delete-question * {
  margin-left: 0.5rem;
}
span.icon.copy:before,
span.button.copy:before,
button.copy:before {
  font-family: 'gdt';
  content: '\e024';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.copy *,
span.button.copy *,
button.copy * {
  margin-left: 0.5rem;
}
span.icon.create-adolopment:before,
span.button.create-adolopment:before,
button.create-adolopment:before,
span.icon.duplicate:before,
span.button.duplicate:before,
button.duplicate:before,
span.icon.duplicateToAnotherQuestion:before,
span.button.duplicateToAnotherQuestion:before,
button.duplicateToAnotherQuestion:before {
  font-family: 'gdt';
  content: '\e024';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.create-adolopment *,
span.button.create-adolopment *,
button.create-adolopment *,
span.icon.duplicate *,
span.button.duplicate *,
button.duplicate *,
span.icon.duplicateToAnotherQuestion *,
span.button.duplicateToAnotherQuestion *,
button.duplicateToAnotherQuestion * {
  margin-left: 0.5rem;
}
span.icon.add:before,
span.button.add:before,
button.add:before {
  font-family: 'gdt';
  content: '\e025';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.add *,
span.button.add *,
button.add * {
  margin-left: 0.5rem;
}
span.icon.remove:before,
span.button.remove:before,
button.remove:before {
  font-family: 'gdt';
  content: '\e026';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.remove *,
span.button.remove *,
button.remove * {
  margin-left: 0.5rem;
}
span.icon.sort:before,
span.button.sort:before,
button.sort:before {
  font-family: 'gdt';
  content: '\e027';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.sort *,
span.button.sort *,
button.sort * {
  margin-left: 0.5rem;
}
span.icon.nav-arrow-right:before,
span.button.nav-arrow-right:before,
button.nav-arrow-right:before {
  font-family: 'gdt';
  content: '\e028';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.nav-arrow-right *,
span.button.nav-arrow-right *,
button.nav-arrow-right * {
  margin-left: 0.5rem;
}
span.icon.nav-arrow-left:before,
span.button.nav-arrow-left:before,
button.nav-arrow-left:before {
  font-family: 'gdt';
  content: '\e029';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.nav-arrow-left *,
span.button.nav-arrow-left *,
button.nav-arrow-left * {
  margin-left: 0.5rem;
}
span.icon.comment-filled:before,
span.button.comment-filled:before,
button.comment-filled:before {
  font-family: 'gdt';
  content: '\e039';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.comment-filled *,
span.button.comment-filled *,
button.comment-filled * {
  margin-left: 0.5rem;
}
span.icon.comment-blank-show:before,
span.button.comment-blank-show:before,
button.comment-blank-show:before {
  font-family: 'gdt';
  content: '\e018\e070';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.comment-blank-show *,
span.button.comment-blank-show *,
button.comment-blank-show * {
  margin-left: 0.5rem;
}
span.icon.refresh:before,
span.button.refresh:before,
button.refresh:before {
  font-family: 'gdt';
  content: '\e052';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.refresh *,
span.button.refresh *,
button.refresh * {
  margin-left: 0.5rem;
}
span.icon.archive:before,
span.button.archive:before,
button.archive:before {
  font-family: 'gdt';
  content: '\e054';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.archive *,
span.button.archive *,
button.archive * {
  margin-left: 0.5rem;
}
span.icon.bookmark:before,
span.button.bookmark:before,
button.bookmark:before {
  font-family: 'gdt';
  content: '\e053';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.bookmark *,
span.button.bookmark *,
button.bookmark * {
  margin-left: 0.5rem;
}
span.icon.accept-icon,
span.button.accept-icon,
button.accept-icon {
  font-size: 20px;
  font-size: 2rem;
  color: #2ba82e;
}
span.icon.accept-icon:before,
span.button.accept-icon:before,
button.accept-icon:before {
  font-family: 'gdt';
  content: '\e055';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.accept-icon *,
span.button.accept-icon *,
button.accept-icon * {
  margin-left: 0.5rem;
}
span.icon.accept-icon:before,
span.button.accept-icon:before,
button.accept-icon:before {
  vertical-align: baseline;
}
span.icon.decline-icon,
span.button.decline-icon,
button.decline-icon {
  font-size: 20px;
  font-size: 2rem;
  color: #9e0000;
}
span.icon.decline-icon:before,
span.button.decline-icon:before,
button.decline-icon:before {
  font-family: 'gdt';
  content: '\e056';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.decline-icon *,
span.button.decline-icon *,
button.decline-icon * {
  margin-left: 0.5rem;
}
span.icon.decline-icon:before,
span.button.decline-icon:before,
button.decline-icon:before {
  vertical-align: baseline;
}
span.icon.grid-view:before,
span.button.grid-view:before,
button.grid-view:before {
  font-family: 'gdt';
  content: '\e057';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.grid-view *,
span.button.grid-view *,
button.grid-view * {
  margin-left: 0.5rem;
}
span.icon.list-view:before,
span.button.list-view:before,
button.list-view:before {
  font-family: 'gdt';
  content: '\e058';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.list-view *,
span.button.list-view *,
button.list-view * {
  margin-left: 0.5rem;
}
span.icon.send-copy:before,
span.button.send-copy:before,
button.send-copy:before {
  font-family: 'gdt';
  content: '\e059';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.send-copy *,
span.button.send-copy *,
button.send-copy * {
  margin-left: 0.5rem;
}
span.icon.checkmark:before,
span.button.checkmark:before,
button.checkmark:before {
  font-family: 'gdt';
  content: '\e060';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.checkmark *,
span.button.checkmark *,
button.checkmark * {
  margin-left: 0.5rem;
}
span.icon.unarchive:before,
span.button.unarchive:before,
button.unarchive:before {
  font-family: 'gdt';
  content: '\e061';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.unarchive *,
span.button.unarchive *,
button.unarchive * {
  margin-left: 0.5rem;
}
span.icon.start:before,
span.button.start:before,
button.start:before {
  font-family: 'gdt';
  content: '\e059';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.start *,
span.button.start *,
button.start * {
  margin-left: 0.5rem;
}
span.icon.start.icon-right:before,
span.button.start.icon-right:before,
button.start.icon-right:before {
  content: "";
}
span.icon.start.icon-right:after,
span.button.start.icon-right:after,
button.start.icon-right:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e059';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.clear:before,
span.button.clear:before,
button.clear:before,
span.icon.cross:before,
span.button.cross:before,
button.cross:before {
  font-family: 'gdt';
  content: '\e064';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.clear *,
span.button.clear *,
button.clear *,
span.icon.cross *,
span.button.cross *,
button.cross * {
  margin-left: 0.5rem;
}
span.icon.comment:before,
span.button.comment:before,
button.comment:before {
  font-family: 'gdt';
  content: '\e069';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.comment *,
span.button.comment *,
button.comment * {
  margin-left: 0.5rem;
}
span.icon.comment-blank:before,
span.button.comment-blank:before,
button.comment-blank:before {
  font-family: 'gdt';
  content: '\e070';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.comment-blank *,
span.button.comment-blank *,
button.comment-blank * {
  margin-left: 0.5rem;
}
span.icon.collapse:before,
span.button.collapse:before,
button.collapse:before {
  font-family: 'gdt';
  content: '\e071';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.collapse *,
span.button.collapse *,
button.collapse * {
  margin-left: 0.5rem;
}
span.icon.expand:before,
span.button.expand:before,
button.expand:before {
  font-family: 'gdt';
  content: '\e072';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.expand *,
span.button.expand *,
button.expand * {
  margin-left: 0.5rem;
}
span.icon.numeric:before,
span.button.numeric:before,
button.numeric:before {
  font-family: 'gdt';
  content: '\e078';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.numeric *,
span.button.numeric *,
button.numeric * {
  margin-left: 0.5rem;
}
span.icon.percent:before,
span.button.percent:before,
button.percent:before {
  font-family: 'gdt';
  content: '\e077';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.percent *,
span.button.percent *,
button.percent * {
  margin-left: 0.5rem;
}
span.icon.attachment:before,
span.button.attachment:before,
button.attachment:before {
  font-family: 'gdt';
  content: '\e079';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.attachment *,
span.button.attachment *,
button.attachment * {
  margin-left: 0.5rem;
}
span.icon.eye:before,
span.button.eye:before,
button.eye:before {
  font-family: 'gdt';
  content: '\e080';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.eye *,
span.button.eye *,
button.eye * {
  margin-left: 0.5rem;
}
span.icon.plus:before,
span.button.plus:before,
button.plus:before {
  font-family: 'gdt';
  content: '\e081';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.plus *,
span.button.plus *,
button.plus * {
  margin-left: 0.5rem;
}
span.icon.dissemination:before,
span.button.dissemination:before,
button.dissemination:before {
  font-family: 'gdt';
  content: '\e082';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.dissemination *,
span.button.dissemination *,
button.dissemination * {
  margin-left: 0.5rem;
}
span.icon.voting:before,
span.button.voting:before,
button.voting:before {
  font-family: 'gdt';
  content: '\e083';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.voting *,
span.button.voting *,
button.voting * {
  margin-left: 0.5rem;
}
span.icon.algorithms:before,
span.button.algorithms:before,
button.algorithms:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.algorithms *,
span.button.algorithms *,
button.algorithms * {
  margin-left: 0.5rem;
}
span.icon.blind-eye:before,
span.button.blind-eye:before,
button.blind-eye:before {
  font-family: 'gdt';
  content: '\e086';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.blind-eye *,
span.button.blind-eye *,
button.blind-eye * {
  margin-left: 0.5rem;
}
span.icon.gear:before,
span.button.gear:before,
button.gear:before {
  font-family: 'gdt';
  content: '\e087';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.gear *,
span.button.gear *,
button.gear * {
  margin-left: 0.5rem;
}
span.icon.compass:before,
span.button.compass:before,
button.compass:before {
  font-family: 'gdt';
  content: '\e090';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.compass *,
span.button.compass *,
button.compass * {
  margin-left: 0.5rem;
}
span.icon.chapters:before,
span.button.chapters:before,
button.chapters:before {
  font-family: 'gdt';
  content: '\e094';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.chapters *,
span.button.chapters *,
button.chapters * {
  margin-left: 0.5rem;
}
span.icon.user:before,
span.button.user:before,
button.user:before {
  font-family: 'gdt';
  content: '\e088';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.user *,
span.button.user *,
button.user * {
  margin-left: 0.5rem;
}
span.icon.user:before,
span.button.user:before,
button.user:before {
  color: #fff;
}
span.icon.move-to-organization:before,
span.button.move-to-organization:before,
button.move-to-organization:before {
  font-family: 'gdt';
  content: '\e089';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.move-to-organization *,
span.button.move-to-organization *,
button.move-to-organization * {
  margin-left: 0.5rem;
}
span.icon.make-active:before,
span.button.make-active:before,
button.make-active:before,
span.icon.move-to-active:before,
span.button.move-to-active:before,
button.move-to-active:before {
  font-family: 'gdt';
  content: '\e061';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.make-active *,
span.button.make-active *,
button.make-active *,
span.icon.move-to-active *,
span.button.move-to-active *,
button.move-to-active * {
  margin-left: 0.5rem;
}
span.icon.global-export:before,
span.button.global-export:before,
button.global-export:before {
  font-family: 'gdt';
  content: '\e091';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.global-export *,
span.button.global-export *,
button.global-export * {
  margin-left: 0.5rem;
}
span.icon.file-icon:before,
span.button.file-icon:before,
button.file-icon:before {
  font-family: 'gdt';
  content: '\e098';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.file-icon *,
span.button.file-icon *,
button.file-icon * {
  margin-left: 0.5rem;
}
span.icon.more:before,
span.button.more:before,
button.more:before {
  font-family: 'gdt';
  content: '\e101';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.more *,
span.button.more *,
button.more * {
  margin-left: 0.5rem;
}
span.icon.clock:before,
span.button.clock:before,
button.clock:before {
  font-family: 'gdt';
  content: '\e103';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.clock *,
span.button.clock *,
button.clock * {
  margin-left: 0.5rem;
}
span.icon.clock.icon-right:before,
span.button.clock.icon-right:before,
button.clock.icon-right:before {
  content: "";
}
span.icon.clock.icon-right:after,
span.button.clock.icon-right:after,
button.clock.icon-right:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e103';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.filter:before,
span.button.filter:before,
button.filter:before {
  font-family: 'gdt';
  content: '\e106';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.filter *,
span.button.filter *,
button.filter * {
  margin-left: 0.5rem;
}
span.icon.project-locked:before,
span.button.project-locked:before,
button.project-locked:before,
span.icon.locked:before,
span.button.locked:before,
button.locked:before {
  font-family: 'gdt';
  content: '\e107';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.project-locked *,
span.button.project-locked *,
button.project-locked *,
span.icon.locked *,
span.button.locked *,
button.locked * {
  margin-left: 0.5rem;
}
span.icon.project-locked.icon-right:before,
span.button.project-locked.icon-right:before,
button.project-locked.icon-right:before,
span.icon.locked.icon-right:before,
span.button.locked.icon-right:before,
button.locked.icon-right:before {
  content: "";
}
span.icon.project-locked.icon-right:after,
span.button.project-locked.icon-right:after,
button.project-locked.icon-right:after,
span.icon.locked.icon-right:after,
span.button.locked.icon-right:after,
button.locked.icon-right:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e107';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.unlocked:before,
span.button.unlocked:before,
button.unlocked:before {
  font-family: 'gdt';
  content: '\e108';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.unlocked *,
span.button.unlocked *,
button.unlocked * {
  margin-left: 0.5rem;
}
span.icon.unlocked.icon-right:before,
span.button.unlocked.icon-right:before,
button.unlocked.icon-right:before {
  content: "";
}
span.icon.unlocked.icon-right:after,
span.button.unlocked.icon-right:after,
button.unlocked.icon-right:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e108';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.attached-to-ds:before,
span.button.attached-to-ds:before,
button.attached-to-ds:before {
  font-family: 'gdt';
  content: '\e109';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.attached-to-ds *,
span.button.attached-to-ds *,
button.attached-to-ds * {
  margin-left: 0.5rem;
}
span.icon.connections:before,
span.button.connections:before,
button.connections:before {
  font-family: 'gdt';
  content: '\e051';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.connections *,
span.button.connections *,
button.connections * {
  margin-left: 0.5rem;
}
span.icon.reference:before,
span.button.reference:before,
button.reference:before {
  font-family: 'gdt';
  content: '\e113';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
span.icon.reference *,
span.button.reference *,
button.reference * {
  margin-left: 0.5rem;
}
span.icon.inactive,
span.button.inactive,
button.inactive {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
span.icon.inactive:hover,
span.button.inactive:hover,
button.inactive:hover {
  opacity: 0.2 !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)" !important;
  filter: alpha(opacity=20) !important;
}
span.icon:hover:not(:disabled),
span.button:hover:not(:disabled),
button:hover:not(:disabled) {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
span.icon.large,
span.button.large,
button.large {
  font-size: 17px;
  font-size: 1.7rem;
  padding: 1rem;
}
span.icon:before,
span.button:before,
button:before,
span.icon:after,
span.button:after,
button:after {
  color: #3783a5;
}
span.icon.disabled,
span.button.disabled,
button.disabled {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
  color: #f00;
}
span.icon:disabled,
span.button:disabled,
button:disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
span.icon.ep-btn,
span.button.ep-btn,
button.ep-btn {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
span.icon.ep-btn:disabled,
span.button.ep-btn:disabled,
button.ep-btn:disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.exclamation-mark:before {
  font-family: 'gdt';
  content: '\e075';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.exclamation-mark * {
  margin-left: 0.5rem;
}
.context-help-icon {
  color: #3783a5;
}
.context-help-icon:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.context-help-icon * {
  margin-left: 0.5rem;
}
.warning-icon:before {
  font-family: 'gdt';
  content: '\e065';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.warning-icon * {
  margin-left: 0.5rem;
}
.warning-icon:before {
  vertical-align: initial;
}
.arrow-up-icon:before {
  font-family: 'gdt';
  content: '\e071';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.arrow-up-icon * {
  margin-left: 0.5rem;
}
.arrow-up-icon:before {
  vertical-align: initial;
}
.arrow-down-icon:before {
  font-family: 'gdt';
  content: '\e072';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.arrow-down-icon * {
  margin-left: 0.5rem;
}
.arrow-down-icon:before {
  vertical-align: initial;
}
.info-icon:before {
  font-family: 'gdt';
  content: '\e066';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.info-icon * {
  margin-left: 0.5rem;
}
.info-icon:before {
  vertical-align: initial;
}
.date-icon:before {
  font-family: 'gdt';
  content: '\e103';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.date-icon * {
  margin-left: 0.5rem;
}
.anchor-icon:before {
  font-family: 'gdt';
  content: '\e102';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.anchor-icon * {
  margin-left: 0.5rem;
}
.anchor-icon:before {
  vertical-align: initial;
}
.send-pilot:before {
  font-family: 'gdt';
  content: '\e076';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.send-pilot * {
  margin-left: 0.5rem;
}
.label-disabled,
.label-disabled-disconnected {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.label-disabled-disconnected {
  padding-right: 25px;
  background: url("../images/no-connection.png") no-repeat center right;
}
.editor-container {
  position: relative;
}
.editor-container .content {
  background: #fff;
  border: 1px solid #bfbfbf;
  text-align: left;
  padding: 5px;
  min-height: 150px;
}
.editor-container .static-content {
  cursor: pointer;
}
.editor-container .buttons-react,
.editor-container .buttons,
.editor-container .col-6 {
  padding: 0 !important;
  margin: 0 !important;
}
.editor-container .buttons-react button.cancel,
.editor-container .buttons button.cancel,
.editor-container .col-6 button.cancel {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.editor-container .buttons-react button.apply,
.editor-container .buttons button.apply,
.editor-container .col-6 button.apply {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.editor-container div.buttons,
.editor-container div.editable-content {
  display: none;
}
.editor-container div.top-buttons {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
}
.editor-container.edit-content div.static-content {
  display: none;
}
.editor-container.edit-content div.editable-content {
  display: block;
}
.editor-container.edit-content div.buttons {
  display: inline-block;
  width: 100%;
}
menu[type="toolbar"] .outcome-view:before {
  font-family: 'gdt';
  content: '\e004';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .outcome-view * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .export:before {
  font-family: 'gdt';
  content: '\e009';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .export * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .preview:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .preview * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .save:before {
  font-family: 'gdt';
  content: '\e021';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .save * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .synchronize:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .synchronize * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .print:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .print * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .send-mail:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .send-mail * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .open-file {
  background-position: center center;
}
menu[type="toolbar"] .open-file:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .open-file * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .footnotes:before,
menu[type="toolbar"] .context-help:before {
  display: inline-block;
  padding-bottom: 2px;
  padding-right: 3px;
}
menu[type="toolbar"] button {
  width: 100%;
  height: 100%;
  line-height: 20px;
}
menu[type="toolbar"] button:before {
  color: #3783a5;
}
button:hover:not(:disabled) {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
button:disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.empty .standard-list {
  border-width: 0 !important;
}
.standard-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: table;
  width: 100%;
  overflow: visible;
  font-size: 14px;
}
.standard-list li {
  display: block;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -o-transition: height 0.5s;
  -ms-transition: height 0.5s;
  transition: height 0.5s;
  position: relative;
  height: 40px;
  padding-left: 10px;
  background: #f2f2f2;
  border-bottom: 2px solid #fff;
  overflow: hidden;
}
.standard-list li:last-child {
  border-bottom: 0;
}
.standard-list li.expanded .separated {
  min-height: 40px;
  margin-left: 10px;
  padding-left: 10px;
  margin-right: 40px;
}
.standard-list li.expanded .draft {
  margin-right: 0;
}
.standard-list li.expanded .details {
  overflow: hidden;
}
.standard-list li:hover:not(.expanded),
.standard-list li.current {
  background-color: #f7f7f7;
}
.standard-list li a,
.standard-list li button {
  height: 40px;
}
.standard-list li a,
.standard-list li button,
.standard-list li a:link,
.standard-list li button:link,
.standard-list li a:visited,
.standard-list li button:visited,
.standard-list li a:hover,
.standard-list li button:hover,
.standard-list li a:active,
.standard-list li button:active {
  color: inherit;
  text-decoration: none;
}
.standard-list li button {
  display: block;
  float: right;
  width: 40px;
  background-position: center center;
  border: none;
  border-left: 2px solid #fff;
}
.standard-list li .caption {
  height: 40px;
  padding-right: 60px;
  line-height: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.standard-list li .caption > div {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.standard-list li .caption .evidence-table-progress {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
}
.standard-list li .side-buttons {
  position: absolute;
  width: 40px;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 2px solid #fff;
}
ol.standard-list {
  counter-reset: li;
}
ol.standard-list li {
  height: 40px;
  line-height: 40px;
  padding-left: 50px;
}
ol.standard-list li:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
  line-height: 40px;
  border-right: 2px solid #fff;
  text-align: center;
}
ol.standard-list .form-horizontal {
  padding-right: 50px;
}
ol.standard-list .form-horizontal label {
  line-height: 40px;
}
.ui-sortable li {
  cursor: url("../images/openhand.cur"), auto;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.sortable-placeholder {
  border-top: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  background: #cdcdcd !important;
}
.ui-sortable-helper {
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
  margin: 0 auto;
}
.ui-sortable-helper span.dot {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  background: #f6f6f6;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 19px;
  position: absolute;
  border: 1px solid #b5b5b5;
}
.ui-sortable-helper ul {
  position: relative;
}
.ui-sortable-helper ul li {
  background: #f6f6f6;
}
.ui-sortable-helper,
.ui-sortable-helper a {
  cursor: url("../images/closedhand.cur"), auto;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
.buttons-outer {
  padding-top: 10px;
}
menu.buttons {
  padding: 0;
  margin: 0;
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 40px;
}
menu.buttons li {
  display: table-cell;
  text-align: center;
  background: #cecece;
  height: 40px;
  line-height: 40px;
}
menu.buttons li:hover {
  background: #d3d3d3;
}
menu.buttons li a {
  display: block;
}
menu.buttons li a,
menu.buttons li a:link,
menu.buttons li a:visited,
menu.buttons li a:hover,
menu.buttons li a:active {
  color: inherit;
  text-decoration: none;
}
menu.buttons li a span {
  display: inline-block;
}
menu.buttons.questions {
  margin-top: 15px;
}
.scrolled.mCustomScrollbar + menu.buttons {
  padding-right: 49px;
}
ol + menu.buttons,
menu.buttons.after-ol {
  padding-left: 0;
  padding-right: 0;
}
ol + menu.buttons button,
menu.buttons.after-ol button {
  margin: -10px !important;
}
.scrolled {
  overflow: auto;
  position: relative;
}
.mCSB_container {
  margin-right: 0px;
}
.sticky-header + .scrolled tr:first-child td,
.sticky-header + .scrolled tr:first-child th {
  border-top: none;
}
.native-scroll {
  position: static;
}
.box-loading {
  height: 200px;
  background: url("../images/loader-big.gif") center center no-repeat;
}
.uppercase {
  text-transform: uppercase;
}
#content {
  display: block;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.loading-screen {
  width: 415px;
  margin: 149px auto;
}
.loading-screen .messages {
  padding: 72.66666666666667px 14% 7%;
  background: #eaeaea;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  position: relative;
}
.loading-screen .messages div {
  font-size: 16px;
  text-align: center;
}
.loading-screen .messages div.warning {
  color: #f00;
  margin-bottom: 1rem;
  display: none;
}
.loading-screen .messages div.loading-text {
  font-size: 18px;
  font-size: 1.8rem;
}
.loading-screen .messages .logo {
  background: url("../images/logo_new.png") top left no-repeat;
  text-indent: -9999px;
  width: 218px;
  height: 48px;
  display: block;
  position: absolute;
  margin: 0;
  top: 24px;
  left: 98.5px;
}
html.non-webkit .loading-screen .warning.non-webkit {
  display: block;
}
html.lt-ie10 .loading-screen .warning.non-webkit {
  display: none;
}
html.lt-ie10 .loading-screen .warning.old-ie {
  display: block;
}
html.lt-ie10 .loading-screen .box-loading {
  display: none;
}
.input-with-button {
  position: relative;
}
.input-with-button button.input-button {
  position: absolute;
  height: 32px;
  width: 32px;
  display: inline;
  border: none;
  float: none;
  top: -7px;
  right: 3px;
  border: 1px solid #bfbfbf;
  background-color: #ebebe4;
}
.input-with-button button.input-button:before {
  vertical-align: initial;
  line-height: 22px;
}
.text-area-container {
  position: relative;
  min-height: 32px;
  width: 100%;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  white-space: pre-wrap;
}
.text-area-container textarea {
  width: 100%;
}
.text-area-container .buttons {
  position: relative !important;
  width: 100%;
}
.text-area-container .buttons button {
  border: 0;
  border-right: 2px solid #fff;
  width: 50%;
}
.text-area-container .buttons button:last-child {
  border-right: 0;
}
.comments-section .title,
.comments-section .members-comments,
.comments-section .admin-comment {
  display: inline-block;
  padding-bottom: 10px;
  vertical-align: top;
}
.comments-section .title {
  width: 14%;
}
.comments-section .members-comments,
.comments-section .admin-comment {
  width: 85%;
}
.comments-section .members-comments {
  border-top: 1px solid #bfbfbf;
}
.comments-section .member-comment {
  min-height: 30px;
  border: 1px solid #bfbfbf;
  border-top: 0;
  padding: 5px;
}
.text-with-limit {
  position: relative;
}
.text-with-limit input,
.text-with-limit textarea {
  padding-right: 45px;
}
.text-with-limit .inner-text {
  position: absolute;
  line-height: initial;
  top: 0;
  right: 5px;
  color: #808080;
}
.for-print {
  display: none;
}
.collapsible-container {
  position: relative;
}
.collapsible-container .collapsible-toggle {
  position: absolute;
  left: -10px;
}
.collapsible-container .collapsible-toggle.collapsed:before {
  font-family: 'gdt';
  content: '\e019';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.collapsible-container .collapsible-toggle.collapsed * {
  margin-left: 0.5rem;
}
.collapsible-container .collapsible-toggle.expanded:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.collapsible-container .collapsible-toggle.expanded * {
  margin-left: 0.5rem;
}
.add-new-member {
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  background: url("../images/svg/new-member.svg") no-repeat;
  background-position: top center;
  -webkit-background-size: 35px 35px;
  -moz-background-size: 35px 35px;
  background-size: 35px 35px;
  width: 100px;
  color: #3883a5;
  padding-top: 35px;
}
.add-new-member:hover {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.add-new-member:disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  color: #3883a5;
  pointer-events: none;
}
.add-new-member:disabled:hover {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.validation-container {
  position: relative;
}
.notifier-screen.notifier-theme-gdt {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.medium-editor-toolbar {
  z-index: 11000;
}
@font-face {
  font-family: 'gdt';
  src: url("../fonts/gdt.eot");
  src: url("../fonts/gdt.eot?#iefix") format('embedded-opentype'), url("../fonts/gdt.woff") format('woff'), url("../fonts/gdt.ttf") format('truetype'), url("../fonts/gdt.svg#gdt") format('svg');
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: 'gdt';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.quality-sign {
  font-family: 'GRADE-quality', Cambria, Helvetica, Arial;
  font-weight: normal;
  font-size: 14px;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
@media print {
  #header-container,
  #topbar,
  #sidebar-container {
    display: none;
  }
  .outer-container {
    position: static;
    padding: 0;
  }
  table {
    font-family: "Arial Narrow", Arial, sans-serif;
  }
  .for-print {
    display: inherit;
  }
  .no-print {
    display: none;
  }
  .dont-split {
    page-break-inside: avoid;
  }
  .mCSB_container {
    margin-right: inherit;
  }
  .mCustomScrollBox .mCSB_scrollTools {
    padding: 6px 0;
    width: inherit;
  }
  .mCustomScrollBox .mCSB_scrollTools .mCSB_draggerRail {
    width: 6px;
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: #cecece;
    -webkit-box-shadow: 1px 1px 1px 1px rgba(78,78,78,0.5) inset;
    box-shadow: 1px 1px 1px 1px rgba(78,78,78,0.5) inset;
  }
  .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #9bbde0;
  }
  .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
  }
  .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
  .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
.presentations .content-bordered-with-switch .content-editable-with-switch .content {
  min-height: 38px;
}
.options-select-container.disabled {
  cursor: initial;
}
.options-select-container.disabled .select-input {
  background-color: #d1d1d1;
}
.search-container {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.search-container.with-search-filter input[type=text] {
  border-left: none;
}
.search-container .input-container,
.search-container button {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  height: 38px;
  line-height: 36px;
}
.search-container button {
  width: 40px;
  background-color: #fff;
  border: 1px solid #009cca;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-left: none;
}
.search-container button:before {
  vertical-align: inherit;
}
.search-container button:disabled {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.search-container .input-container {
  display: inline-block;
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.search-container .input-container .clear-search {
  display: none;
}
.search-container .input-container .clear-search.visible {
  display: inline-block;
  position: absolute;
  border-right: none;
  top: 0;
  right: 0;
  font-size: 14px;
}
.search-container .input-container .clear-search.visible:before {
  font-family: 'gdt';
  content: '\e064';
}
.search-container input[type=text] {
  vertical-align: top;
  line-height: 36px;
  height: 38px;
  background-color: #fff;
  border: 1px solid #009cca;
  border-right: none;
  width: 100%;
}
.search-container input[type=text]::-ms-clear {
  display: none;
}
.search-container.with-search-filter input {
  border-left: none;
}
.ep-select-with-checkboxes__trigger {
  width: 100%;
  max-width: 170px;
  height: 35px;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border: 1px solid #bfbfbf;
}
.ep-select-with-checkboxes__trigger .trigger__label {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  text-align: left;
  margin-left: 5px;
}
.ep-select-with-checkboxes__trigger .ep-expand-button {
  margin-top: 0px;
}
.ep-select-with-checkboxes__trigger .ep-expand-button svg {
  fill: #3883a5;
}
.ep-select-with-checkboxes__trigger .ep-expand-button--collapsed {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  text-align: left;
}
.options-select-container {
  height: 35px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: visible;
  position: relative;
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  outline: none;
}
.options-select-container .select-input {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 100%;
  line-height: inherit;
  padding: 0 10px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #bfbfbf;
  outline: none;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.options-select-container .select-input.sign-position-left .trigger__label,
.options-select-container .select-input.sign-position-left .select-value {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.options-select-container .select-input.sign-position-left .ep-expand-button--collapsed,
.options-select-container .select-input.sign-position-left .select-sign {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  text-align: left;
}
.options-select-container .select-input.sign-position-right .trigger__label,
.options-select-container .select-input.sign-position-right .select-value {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.options-select-container .select-input.sign-position-right .ep-expand-button--expanded,
.options-select-container .select-input.sign-position-right .select-sign {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  text-align: right;
}
.options-select-container .select-input .select-value,
.options-select-container .select-input .select-sign {
  display: inline-block;
  padding: 0;
}
.options-select-container .select-input .select-sign {
  width: 20px;
  float: right;
  font-size: 13px;
  text-align: center;
  color: #3883a5;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.options-select-container .select-input .select-sign:before {
  font-family: 'gdt';
  content: '\e072';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.options-select-container .select-input .select-sign * {
  margin-left: 0.5rem;
}
.options-select-container .select-input .select-value {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: pre;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.options-select-container.with-icon .select-input {
  padding-left: 2px;
}
.options-select-container.with-icon .select-icon {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  padding: 3px 6px;
  background: #3783a5;
  margin-right: 10px;
}
.options-select-container__options {
  top: 35px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
  border: 1px solid #bfbfbf;
  margin-top: -1px;
  background-color: #fff;
  list-style: none;
  z-index: 111;
}
.options-select-container__options.with-numbered-options {
  counter-reset: options;
}
.options-select-container__options.with-numbered-options .option {
  counter-increment: options;
}
.options-select-container__options.with-numbered-options .option:before {
  display: inline-block;
  content: counter(options) '.';
  margin-right: 10px;
}
.options-select-container__options .option {
  padding: 0 30px 0 10px;
  cursor: pointer;
}
.options-select-container__options .option:hover {
  background: #eaeaea;
}
.options-select-container__options .option.selected {
  background: #eaeaea;
}
.options-select-container__options .option[disabled] {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: not-allowed;
}
.options-select-container__options .options-group {
  cursor: default;
}
.options-select-container__options .options-group .options-group__caption {
  font-weight: bold;
  padding-left: 5px;
}
.options-select-container__options .options-group .options-group__options ul {
  margin: 10px 0;
}
.options-select-container__options ul .option {
  padding: 0 22px 0 5px;
  line-height: 26px;
  height: 26px;
}
.new-question-group-drop-area {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f3fcff;
  border: 1px solid #009cca;
  border-style: dashed;
  -webkit-transition: background-color 0.5s ease;
  -moz-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  -ms-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.new-question-group-drop-area.about-to-drop {
  background: #d2dfed;
}
.new-question-group-drop-area.hovered {
  background-color: #d2dfed;
}
.questions-list-container {
  position: relative;
  counter-reset: questions;
}
.questions-list-container .items {
  margin-bottom: 10px;
}
.questions-list-container .questions-list-container__questions-group {
  min-height: 50px;
  background-color: #fff;
}
.questions-list-container .questions-list-container__questions-group.with-caption {
  margin-bottom: 40px;
  border: 1px solid #009cca;
  padding: 0 10px 10px;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title,
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls {
  line-height: 50px;
  font-weight: bold;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title {
  padding-left: 9px;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls {
  overflow: hidden;
  position: relative;
  min-height: 50px;
  outline: none;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-text,
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-text--editing,
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls,
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls--editing {
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  -o-transition: -o-transform 0.5s ease;
  -ms-transition: -ms-transform 0.5s ease;
  transition: transform 0.5s ease;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-text,
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-text--editing {
  position: absolute;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-text {
  min-height: 50px;
  padding-right: 40px;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-text--editing {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 100%;
  padding-right: 80px;
  padding-left: 0;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-text--editing input {
  width: 100%;
  background-color: #fff;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls,
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls--editing {
  position: absolute;
  top: 0;
  right: 0;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls .btn-icon-container,
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls--editing .btn-icon-container {
  display: inline-block;
  width: 40px;
  text-align: center;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls .btn-icon-container button,
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls--editing .btn-icon-container button {
  vertical-align: text-bottom;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls--editing {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls .caption-controls--editing .btn-icon-container {
  display: inline-block;
  width: 40px;
  text-align: center;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls.editing .caption-text {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls.editing .caption-text--editing {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls.editing .caption-controls {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__group-title--with-controls.editing .caption-controls--editing {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__questions-container {
  min-height: 22px;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__questions-container.empty {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f3fcff;
  border: 1px solid #009cca;
  border-style: dashed;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__questions-container.empty.about-to-drop {
  background: #d2dfed;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__questions-container .questions-list-container__question {
  position: relative;
  padding-left: 42px;
  counter-increment: questions;
  background: #f2f2f2;
  border-bottom: 2px solid #fff;
  font-size: 14px;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__questions-container .questions-list-container__question:before {
  position: absolute;
  left: 0;
  width: 40px;
  line-height: 40px;
  height: 100%;
  content: counter(questions);
  text-align: center;
  border-right: 2px solid #fff;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__questions-container .questions-list-container__question:hover {
  background-color: #f7f7f7;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__questions-container .questions-list-container__question.is-dragging {
  background: #d2dfed;
  color: #d2dfed;
}
.questions-list-container .questions-list-container__questions-group .questions-list-container__questions-container .questions-list-container__question.is-dragging:before {
  border-right: 0;
}
.collapsible-edit-container {
  position: relative;
  outline: none;
}
.collapsible-edit-container .caption-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.collapsible-edit-container .caption-row .current-question {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 10px;
}
.collapsible-edit-container .caption-row .current-question .current-question__text {
  display: block;
  line-height: 40px;
  min-height: 40px;
  max-height: 40px;
  overflow: hidden;
  -o-text-overflow: ellpsis;
  text-overflow: ellpsis;
}
.collapsible-edit-container.--controls-left .caption-row__caption,
.collapsible-edit-container.--controls-left .collapsed-content {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.collapsible-edit-container.--controls-left .controls {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.collapsible-edit-container.--controls-right .caption-row__caption,
.collapsible-edit-container.--controls-right .collapsed-content {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.collapsible-edit-container.--controls-right .controls {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.collapsible-edit-container .collapsed-content-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 8px;
}
.collapsible-edit-container .caption-row {
  position: relative;
  min-height: 40px;
}
.collapsible-edit-container .caption-row .controls {
  line-height: 40px;
}
.collapsible-edit-container .caption-row.current-project-name {
  max-height: 20px;
  overflow: hidden;
  max-width: calc(100vw - 315px);
}
.collapsible-edit-container .caption-row__caption,
.collapsible-edit-container .collapsed-content {
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
}
.collapsible-edit-container .controls {
  -webkit-flex: 0 0 40px;
  -ms-flexbox: 0 0 40px;
  flex: 0 0 40px;
  vertical-align: middle;
  text-align: center;
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  align-self: start;
}
.collapsible-edit-container .controls .btn-icon-container {
  margin-left: 0;
}
.dynamic-height-container {
  -webkit-transition: height 0.3s ease-out, width 0.3s ease-out;
  -moz-transition: height 0.3s ease-out, width 0.3s ease-out;
  -o-transition: height 0.3s ease-out, width 0.3s ease-out;
  -ms-transition: height 0.3s ease-out, width 0.3s ease-out;
  transition: height 0.3s ease-out, width 0.3s ease-out;
  overflow: hidden;
}
.gdt-navigation-bar {
  position: relative;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  height: 40px;
}
.gdt-navigation-bar .gdt-navigation-bar__item {
  position: relative;
  height: 100%;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  line-height: 14px;
  font-size: 13px;
  color: #606060;
}
.gdt-navigation-bar .gdt-navigation-bar__item:hover {
  color: #fff;
  font-weight: bold;
  background-image: -webkit-linear-gradient(bottom left, #2caac4 49%, transparent 50%), -webkit-linear-gradient(top left, #2caac4 49%, transparent 50%), -webkit-linear-gradient(left, #2caac4, #2caac4);
  background-image: -moz-linear-gradient(bottom left, #2caac4 49%, transparent 50%), -moz-linear-gradient(top left, #2caac4 49%, transparent 50%), -moz-linear-gradient(left, #2caac4, #2caac4);
  background-image: -o-linear-gradient(bottom left, #2caac4 49%, transparent 50%), -o-linear-gradient(top left, #2caac4 49%, transparent 50%), -o-linear-gradient(left, #2caac4, #2caac4);
  background-image: -ms-linear-gradient(bottom left, #2caac4 49%, transparent 50%), -ms-linear-gradient(top left, #2caac4 49%, transparent 50%), -ms-linear-gradient(left, #2caac4, #2caac4);
  background-image: linear-gradient(to top right, #2caac4 49%, transparent 50%), linear-gradient(to bottom right, #2caac4 49%, transparent 50%), linear-gradient(to right, #2caac4, #2caac4);
  background-position: top right, bottom right, top left;
  background-repeat: no-repeat, no-repeat, no-repeat;
  -webkit-background-size: calc(40px/4) 50%, calc(40px/4) 50%, 100% 100%;
  -moz-background-size: calc(40px/4) 50%, calc(40px/4) 50%, 100% 100%;
  background-size: calc(40px/4) 50%, calc(40px/4) 50%, 100% 100%;
  -webkit-background-clip: border, border, content;
  -moz-background-clip: border, border, content;
  background-clip: border-box, border-box, content-box;
}
.gdt-navigation-bar .gdt-navigation-bar__item:hover:not(:first-child):before {
  background-image: -webkit-linear-gradient(bottom left, #b3b3b3 49%, #2caac4 50%);
  background-image: -moz-linear-gradient(bottom left, #b3b3b3 49%, #2caac4 50%);
  background-image: -o-linear-gradient(bottom left, #b3b3b3 49%, #2caac4 50%);
  background-image: -ms-linear-gradient(bottom left, #b3b3b3 49%, #2caac4 50%);
  background-image: linear-gradient(to top right, #b3b3b3 49%, #2caac4 50%);
}
.gdt-navigation-bar .gdt-navigation-bar__item:hover:not(:first-child):after {
  background-image: -webkit-linear-gradient(top left, #b3b3b3 49%, #2caac4 50%);
  background-image: -moz-linear-gradient(top left, #b3b3b3 49%, #2caac4 50%);
  background-image: -o-linear-gradient(top left, #b3b3b3 49%, #2caac4 50%);
  background-image: -ms-linear-gradient(top left, #b3b3b3 49%, #2caac4 50%);
  background-image: linear-gradient(to bottom right, #b3b3b3 49%, #2caac4 50%);
}
.gdt-navigation-bar .gdt-navigation-bar__item:not(:last-child) {
  padding-right: calc(40px/4);
}
.gdt-navigation-bar .gdt-navigation-bar__item:not(:first-child):before,
.gdt-navigation-bar .gdt-navigation-bar__item:not(:first-child):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(40px/4);
  height: 50%;
  background-color: inherit;
  background-image: -webkit-linear-gradient(bottom left, #b3b3b3 49%, transparent 50%);
  background-image: -moz-linear-gradient(bottom left, #b3b3b3 49%, transparent 50%);
  background-image: -o-linear-gradient(bottom left, #b3b3b3 49%, transparent 50%);
  background-image: -ms-linear-gradient(bottom left, #b3b3b3 49%, transparent 50%);
  background-image: linear-gradient(to top right, #b3b3b3 49%, transparent 50%);
  -webkit-transform-origin: bottom right;
  -moz-transform-origin: bottom right;
  -o-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
  -webkit-transform: skewX(26.57deg);
  -moz-transform: skewX(26.57deg);
  -o-transform: skewX(26.57deg);
  -ms-transform: skewX(26.57deg);
  transform: skewX(26.57deg);
}
.gdt-navigation-bar .gdt-navigation-bar__item:not(:first-child):after {
  top: 50%;
  background-image: -webkit-linear-gradient(top left, #b3b3b3 49%, transparent 50%);
  background-image: -moz-linear-gradient(top left, #b3b3b3 49%, transparent 50%);
  background-image: -o-linear-gradient(top left, #b3b3b3 49%, transparent 50%);
  background-image: -ms-linear-gradient(top left, #b3b3b3 49%, transparent 50%);
  background-image: linear-gradient(to bottom right, #b3b3b3 49%, transparent 50%);
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -o-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  -webkit-transform: skewX(-26.57deg);
  -moz-transform: skewX(-26.57deg);
  -o-transform: skewX(-26.57deg);
  -ms-transform: skewX(-26.57deg);
  transform: skewX(-26.57deg);
}
.icon-button__with-spinner {
  position: relative;
}
.icon-button__with-spinner .spinner-el {
  position: absolute;
  top: 40%;
  left: 50%;
}
.gdt-scrollable__container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.gdt-scrollable__container.mounted {
  position: absolute;
}
.gdt-scrollable__container:hover {
  overflow-y: auto;
}
.gdt-scrollable__container .scrollable__content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.gdt-rating-container {
  width: 100%;
  text-align: center;
}
.gdt-rating-element {
  cursor: pointer;
  display: inline-block;
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  filter: alpha(opacity=10);
  padding: 0 12px;
  width: 18px;
  height: 20px;
  background: url("../images/rating-star.svg") center center no-repeat;
}
.gdt-rating-element--active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.gdt-flex-table {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}
.gdt-flex-table-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.gdt-flex-table-cell {
  width: 100px;
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.subscriptions-modal {
  width: 700px;
}
.subscriptions-modal .ep-modal__content {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.subscriptions-modal .ep-modal__content .licences header h1,
.subscriptions-modal .ep-modal__content .licences footer a {
  color: #000;
}
.subscriptions-modal .ep-modal__content .licences footer {
  line-height: 2.2rem;
}
.subscriptions-modal .ep-modal__content .licences h2 {
  text-align: left;
}
.PopupBox_Container .PopupBox_Content {
  outline: none;
}
.items-list-container .items-list-container__item,
.items-list-container--numbered .items-list-container__item {
  position: relative;
  width: 100%;
  height: 40px;
  background: #f2f2f2;
  border-bottom: 2px solid #fff;
  font-size: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.items-list-container--numbered {
  counter-reset: numbered-items;
}
.items-list-container--numbered .items-list-container__item {
  counter-increment: numbered-items;
}
.items-list-container--numbered .items-list-container__item:before {
  width: 40px;
  line-height: 40px;
  margin-right: 8px;
  -webkit-flex: 0 0 40px;
  -ms-flexbox: 0 0 40px;
  flex: 0 0 40px;
  content: counter(numbered-items);
  text-align: center;
  border-right: 2px solid #fff;
}
label.dont-show-again {
  display: block;
  margin: 20px 0;
}
label.dont-show-again input {
  margin-right: 10px;
}
label.dont-show-again span {
  vertical-align: text-bottom;
}
.select-option-annotation {
  width: 400px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
}
.select-option-annotation a {
  cursor: pointer;
}
.gdt-multi-select label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.gdt-multi-select label input[type="checkbox"] {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 10px;
}
.gdt-multi-select label div {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  padding: 10px;
}
.gdt-multi-select .gdt-multi-select__header div {
  font-weight: bold;
}
.labeled-input {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.labeled-input.label-position-left .labeled-input__label {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.labeled-input.label-position-left .labeled-input__input {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.labeled-input.label-position-right .labeled-input__label {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.labeled-input.label-position-right .labeled-input__input {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.labeled-input .labeled-input__label {
  margin-right: 10px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.labeled-input .labeled-input__input {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  background-color: #fff;
}
.gdt-collapsible-chapter {
  position: relative;
}
.gdt-collapsible-chapter:not(:first-child) .caption-row {
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.4), rgba(0,0,0,0));
  background-image: -moz-linear-gradient(top, rgba(0,0,0,0.4), rgba(0,0,0,0));
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.4), rgba(0,0,0,0));
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.4), rgba(0,0,0,0));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0));
  -webkit-background-size: 100% 12px;
  -moz-background-size: 100% 12px;
  background-size: 100% 12px;
  background-position: top left;
  background-repeat: no-repeat;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.gdt-collapsible-chapter:last-child .caption-row {
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
}
.gdt-collapsible-chapter:hover .caption-row,
.gdt-collapsible-chapter.expanded .caption-row {
  -webkit-box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
  background-color: #005aa5;
  color: #fff;
}
.gdt-collapsible-chapter .controls button:before {
  color: #ffa745;
}
.gdt-collapsible-chapter .caption-row {
  position: relative;
  padding: 10px 15px;
  border: 0px solid #fff;
  background-color: #f2f2f2;
  color: #2d3236;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  -moz-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  -o-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  -ms-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}
.gdt-collapsible-chapter .collapsed-content-row {
  margin-top: 10px;
  padding: 0 0 13px;
}
.gdt-collapsible-chapter .collapsed-content-row .collapsed-content {
  margin-right: -40px;
}
.gdt-popover-content-box {
  padding: 15px;
  border: 1px solid #29abe2;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
  background: #fff;
}
.ellipsized {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.gdt-limits-content-box {
  padding: 20px 0;
  text-align: center;
  background-color: #fff8de;
  font-size: 16px;
}
h2.toolbar-title {
  padding: 10px 10px 0;
  font-size: 18px;
}
h2.toolbar-title.dissemination {
  text-align: center;
  font-size: 21px;
}
h2.toolbar-title.mobile,
h2.toolbar-title.decision-aids {
  padding: 10px 30px 0;
}
span.placeholder {
  color: #757575;
}
.asterixed:after {
  display: inline-block;
  content: "*";
  color: #f00;
}
.cursor-pointer {
  cursor: pointer;
}
.create-update-form-modal .extraction-form {
  margin-bottom: 10px;
}
.create-update-form-modal .extraction-form__group {
  margin: 0;
}
.create-update-form-modal .extraction-field .extraction-field__name {
  width: 100px;
}
.create-update-form-modal .extraction-field .extraction-field__value {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  max-width: initial;
  margin-right: 0;
}
.create-update-form-modal .extraction-field .extraction-field__value > div {
  max-width: initial;
}
